@import "@sweetalert2/theme-dark/dark.scss";

.swal2-popup,
.swal2-modal,
.swal2-icon-success,
.swal2-show {
  background: #161616;
  .swal2-title {
    background: transparent;
  }

  .swal2-html-container {
    background: transparent;
  }

  .swal2-icon,
  .swal2-success,
  .swal2-icon-show {
    .swal2-success-ring {
      background: transparent;
    }
    overflow: hidden;
  }

  .swal2-confirm,
  .swal2-styled {
    background-color: #ff8c00;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 35rem;
  height: 35rem;
  margin-top: 12vh;
  margin-left: 30vw;

  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 8px 32px 0 rgba(178, 119, 41, 0.37);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  .loginTitle {
    font-weight: 900;
    font-size: 3rem;
    background: transparent;
  }
  .loginButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff8c00;
    border-radius: 10px;
    margin-top: 2.756rem;
    cursor: pointer;
    width: 17rem;
    height: 3.2rem;
    .loginButtonText {
      color: #ffffff;
      font-size: 1rem;
      background: transparent;
      text-align: center;
    }
    .googleImage {
      width: 3rem;
      height: 3rem;
      background: transparent;
      margin-right: 1rem;
    }
  }
  .orText {
    color: #aeaeae;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  input {
    margin-bottom: 1rem;
    background: #333333;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    border: none;
    outline: none;
    border-radius: 10px;
    padding-bottom: 1rem;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff8c00;
    border-radius: 10px;
    margin-top: 2rem;
    border: none;
    width: 14vw;
    height: 6vh;
    cursor: pointer;
  }
  .dontHaveAccount {
    margin-top: 1.4rem;
    color: #aeaeae;
    background: transparent;
    a {
      background: transparent;
      color: #ff8c00;
      text-decoration: none;
      :hover {
        cursor: pointer;
      }
    }
  }
}
