.paymentContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 1024px) {
    > div {
      display: flex;
      flex-direction: row;
      gap: 2rem;
    }
  }

  .pricingTitle {
    margin-bottom: 2rem;
    font-size: 2.25rem;
    line-height: 2.9rem;
    overflow: hidden;
    text-align: center;
  }

  .paymentForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 36rem;
    text-align: center;

    cursor: pointer;

    &.active {
      box-shadow: 0 20px 40px 0 rgba(255, 140, 0, 0.1); /* Adding a box shadow to the bottom */
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 0;
      width: 100%;
    }

    .innerForm {
      padding: 2rem;
      border-radius: 0.5rem;
      background-color: #333;
      box-shadow: 0 1px 3px 0 rgba(188, 187, 187, 0.1),
        0 1px 2px 0 rgba(188, 187, 187, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (min-width: 1024px) {
        width: 100%;
      }
    }
    .row {
      display: flex;
      background: transparent;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.25rem;

      .discount {
        border-radius: 20px;
        width: 5vw;
        height: 4vh;
        background: linear-gradient(
          to right bottom,
          #ff8c00,
          #fa9607,
          #f5a013,
          #f1a91f,
          #ecb12a
        );
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.4rem;
        .discountText {
          font-weight: bold;
          font-size: 0.7rem;
          background: transparent;
        }
      }
      .planName {
        font-size: 1.25rem;
        line-height: 1.75rem;
        background: transparent;
      }
    }

    .planPrice {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      font-size: 3rem;
      line-height: 1;
      overflow: hidden;
      .dollar {
        margin-right: 0.75rem;
        font-size: 1.875rem;
        line-height: 2.25rem;
        background: transparent;
      }
    }
    .planDetails {
      display: flex;
      margin-top: 2rem;
      margin-bottom: 2rem;
      flex-direction: column;
      justify-content: center;
      list-style-type: none;
      background: transparent;

      .planDetail {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        background: transparent;
        border-top-width: 1px;
      }
      .spPlanDetail {
        padding-top: 0.75rem;
        background: transparent;
        padding-bottom: 0.75rem;
      }
    }
    .StripeElement {
      height: 40px;
      padding: 10px 12px;
      width: 100%;
      color: #ffffff;
      background-color: #ffff;
      border: 1px solid transparent;
      border-radius: 4px;

      box-shadow: 0 1px 3px 0 #e6ebf1;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;
      > div > iframe {
        background: #ffffff;
      }
    }

    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
      border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
      background-color: #fefde5 !important;
    }
    .purchaseBtn {
      display: block;
      display: flex;
      border-radius: 10px;
      height: 6vh;
      width: 12vw;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1.5rem;
      align-self: center;
      margin-top: 1.5rem;
      outline: none;
      background: #ff8c00;
      font-weight: 600;
      border: none;
      font-size: 1rem;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
