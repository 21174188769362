@import "@sweetalert2/theme-dark/dark.scss";

.swal2-popup,
.swal2-modal,
.swal2-icon-success,
.swal2-show {
  background: #161616;
  .swal2-title {
    background: transparent;
  }

  .swal2-html-container {
    background: transparent;
  }

  .swal2-icon,
  .swal2-success,
  .swal2-icon-show {
    .swal2-success-ring {
      background: transparent;
    }
    overflow: hidden;
  }

  .swal2-confirm,
  .swal2-styled {
    background-color: #ff8c00;
  }
}

.contactContainer {
  width: 60vw;
  padding: 2rem;
  padding-bottom: 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  background: #333;
  border-radius: 10px;
  margin-bottom: 0.6rem;
  .leftContactContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin-right: 1rem;
    flex-direction: column;
    background: transparent;
    .contactTitle {
      font-size: 3rem;
      margin-bottom: 2rem;
      background: transparent;
      font-weight: 600;
      text-align: center;
    }
    .contactDescription {
      text-align: center;
      font-size: 1.4rem;
      background: transparent;
    }
  }
  .rightContactContainer {
    width: 50%;
    background: transparent;
    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      background: transparent;
      .inputField {
        background: transparent;
        margin-bottom: 0.5rem;
      }
      input,
      textarea {
        background: transparent;
        border: none;
        outline: none;
        border-radius: 10px;
        background: #161616;
        padding: 1rem;
      }
    }
    .sendButton {
      background: #ff8c00;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      width: 10rem;
      height: 3rem;
      border-radius: 10px;
      flex-direction: row;
      .sendButtonText {
        font-weight: 500;
        background: transparent;
        margin-left: 1rem;
      }
      svg {
        background: transparent;
      }
    }
  }
}
