.footer {
  padding: 2rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0 8px 32px 0 rgba(178, 119, 41, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  .rowFooter {
    display: flex;
    align-items: center;
    width: 80vw;
    background: transparent;
    flex-direction: row;
    justify-content: space-between;

    .leftFooter,
    .rightFooter {
      background: transparent;
      span {
        background: transparent;
        cursor: pointer;
      }
    }
    .rightFooter {
      width: 19.2rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .footer {
    .rowFooter {
      flex-direction: column;
      .leftFooter {
        margin-right: 7vw;
        margin-bottom: 1rem;
      }
    }
  }
}
