.header {
  display: flex;
  padding: 2.5rem;
  justify-content: space-between;
  align-items: center;
  padding-left: 6rem;
  flex-direction: row;
  padding-right: 6rem;
  background-color: #161616;
  position: sticky;
  position: -webkit-sticky;
  top: 0;

  .leftHeader {
    font-weight: 800;
    font-size: 2rem;
    cursor: pointer;
  }

  .rightHeader {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .headerOption {
      cursor: pointer;
      margin-left: 2rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    padding: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .leftHeader {
      font-size: 1.8rem;
    }

    .rightHeader {
      width: auto;
      font-size: 1.4rem;
    }
  }
}
