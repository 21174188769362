.questionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  color: white;
  width: 50rem;
  border-bottom: 1px solid #555555;
  margin-left: auto;
  margin-right: auto;

  .question {
    font-size: 1.6rem;
    width: 40rem;
    font-weight: 500;
    cursor: pointer;
  }
}

.answer {
  width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.4rem;
}

@media screen and (max-width: 480px) {
  .questionContainer {
    width: 18rem;
    max-width: 40rem;
    svg {
      font-size: 2rem;
    }
    .question {
      width: 100%;
      overflow: hidden;
    }
  }

  .answer {
    width: 90%;
    max-width: 40rem;
  }
}
