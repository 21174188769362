@import "@sweetalert2/theme-dark/dark.scss";

.swal2-popup,
.swal2-modal,
.swal2-icon-success,
.swal2-show {
  background: #161616;
  .swal2-title {
    background: transparent;
  }

  .swal2-html-container {
    background: transparent;
  }

  .swal2-icon,
  .swal2-success,
  .swal2-icon-show {
    .swal2-success-ring {
      background: transparent;
    }
    overflow: hidden;
  }

  .swal2-confirm,
  .swal2-styled {
    background-color: #ff8c00;
  }
}

.timer {
  background: #333;
  width: 9rem;
  height: 3rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-left: 80vw;
  span,
  svg {
    background: transparent;
  }
}

.loadingContainer {
  z-index: 9999999999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  div {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      background: transparent;
    }
  }
}

.candidateQuestionContainer {
  width: 60vw;
  height: 45vh;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #333;
  margin-top: 4rem;
  border-radius: 10px;
  .candidateQuestion {
    font-size: 2rem;
    font-weight: 600;
    background: transparent;
    margin-bottom: 4rem;
    text-align: center;
  }
  .buttonContainer {
    width: 75vw;
    background: transparent;
    align-items: center;
    justify-content: space-around;
    display: flex;
    cursor: pointer;
    input {
      background: transparent;
    }
    .button,
    .hintButton {
      background-color: #ff8c00;
      border-radius: 10px;
      width: 10rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .hintText {
        font-weight: 500;
        font-size: 0.9rem;
        margin-left: 0.4rem;
        background: transparent;
      }
      svg {
        background: transparent;
        font-size: 1.5rem;
      }
    }
    .button {
      .buttonText {
        background: transparent;
      }
    }
  }
}
