@import "@sweetalert2/theme-dark/dark.scss";

.swal2-popup,
.swal2-modal,
.swal2-icon-success,
.swal2-show {
  background: #161616;
  .swal2-title {
    background: transparent;
  }

  .swal2-html-container {
    background: transparent;
  }

  .swal2-icon,
  .swal2-success,
  .swal2-icon-show {
    .swal2-success-ring {
      background: transparent;
    }
    overflow: hidden;
  }

  .swal2-confirm,
  .swal2-styled {
    background-color: #ff8c00;
  }
}

.dashboardContainer {
  padding-left: 10rem;
  padding-right: 10rem;
  margin-top: 6rem;
  > div {
    background: transparent !important;
  }
  .dashboardHeader {
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6rem;
    .dashboardTitle {
      font-weight: 900;
      background: transparent;
      font-size: 3rem;
    }
    .dashboardBtnContainer {
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: center;
      .newInterviewBtn,
      .resumeScannerBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 13rem;
        cursor: pointer;
        height: 2.7rem;
        border-radius: 5px;
        background-color: #ff8c00;
        .newInterviewText,
        .resumeScannerText,
        svg {
          background: transparent;
          font-size: 0.9rem;
        }
        svg {
          margin-right: 0.4rem;
        }
      }
    }
  }
  .interviewTitles {
    width: 78vw;
    display: grid;
    grid-template-columns: 0.75fr 0.9fr 1fr;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-left: 1vw;

    .interviewTitle {
      font-weight: 500;
      color: #aeaeae;
      text-align: left;
    }
  }
  .row {
    width: 78vw;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 1rem;
    .value,
    svg {
      background: transparent;
    }
    .endInterviewBtn {
      background-color: #ff0000;
      width: 10rem;
      height: 2.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      .endInterviewText {
        font-weight: 500;
        font-size: 0.9rem;
        background: transparent;
      }
    }
    .endInterviewBtn--disabled {
      background-color: #aeaeae;
      width: 10rem;
      height: 2.5rem;
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      .endInterviewText {
        font-weight: 500;
        font-size: 0.9rem;
        background: transparent;
      }
    }
  }
  .noResultsContainer {
    width: 78vw;
    height: 31vh;
    align-self: center;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 1rem;
  }
}

.loadingContainer {
  z-index: 9999999999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  div {
    background: transparent;
    display: flex;
    align-items: center;
    width: 15vw;
    justify-content: center;
    svg {
      background: transparent;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .modalContainer {
    background: rgba(22, 22, 22, 0.8);
    box-shadow: 0 8px 32px 0 rgba(178, 119, 41, 0.37);
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 35vw;
    border: 1px solid rgba(255, 255, 255, 0.18);
    .modalTitle {
      font-size: 2rem;
      background: transparent;
      font-weight: bold;
    }
    .modalDescription {
      color: #aeaeae;
      background: transparent;
      margin-bottom: 3rem;
      font-size: 0.95rem;
    }
    .inputContainer {
      background: transparent;
      flex-direction: column;
      display: flex;
      margin-bottom: 2rem;
      .inputText {
        background: transparent;
        margin-bottom: 1rem;
        text-align: left;
      }
      input {
        border: none;
        outline: none;
        background-color: #333;
        color: "#ffffff";
        padding: 1rem;
        border-radius: 10px;
      }
      .react-toggle {
        touch-action: pan-x;

        display: inline-block;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-left: 1rem;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
      }

      .react-toggle-screenreader-only {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      .react-toggle--disabled {
        cursor: not-allowed;
        opacity: 0.5;
        -webkit-transition: opacity 0.25s;
        transition: opacity 0.25s;
      }

      .react-toggle-track {
        width: 50px;
        height: 24px;
        padding: 0;
        border-radius: 30px;
        background-color: #4d4d4d;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: #000000;
      }

      .react-toggle--checked .react-toggle-track {
        background-color: #19ab27;
      }

      .react-toggle--checked:hover:not(.react-toggle--disabled)
        .react-toggle-track {
        background-color: #128d15;
      }

      .react-toggle-track-check {
        position: absolute;
        width: 14px;
        height: 10px;
        top: 7px;
        margin-top: auto;
        margin-bottom: auto;
        background: transparent;
        line-height: 0;
        left: 8px;
        opacity: 0;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
        svg {
          background: transparent;
        }
      }

      .react-toggle--checked .react-toggle-track-check {
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
      }

      .react-toggle-track-x {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 7px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        right: 10px;
        background: transparent;
        opacity: 1;
        -webkit-transition: opacity 0.25s ease;
        -moz-transition: opacity 0.25s ease;
        transition: opacity 0.25s ease;
        svg {
          background: transparent;
        }
      }

      .react-toggle--checked .react-toggle-track-x {
        opacity: 0;
      }

      .react-toggle-thumb {
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 22px;
        height: 22px;
        border: 1px solid #4d4d4d;
        border-radius: 50%;
        background-color: #fafafa;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        transition: all 0.25s ease;
      }

      .react-toggle--checked .react-toggle-thumb {
        left: 27px;
        border-color: #19ab27;
      }
    }
    .btnContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: transparent;
      .cancelButton,
      .continueButton,
      .uploadPDFBtn,
      .analyzeBtn {
        width: 10rem;
        height: 3rem;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: 500;
          font-size: 0.9rem;
          background: transparent;
        }
      }
      .cancelButton {
        background-color: #ff0000;
      }
      .continueButton,
      .uploadPDFBtn,
      .analyzeBtn {
        background-color: #ff8c00;
      }
    }
  }
}
