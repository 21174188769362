.header {
  display: flex;
  padding: 2.5rem;
  justify-content: space-between;
  align-items: center;
  padding-left: 6rem;
  flex-direction: row;
  padding-right: 6rem;
  background-color: #161616;
  position: sticky;
  position: -webkit-sticky;
  top: 0;

  .leftHeader {
    font-weight: 800;
    font-size: 2rem;
    cursor: pointer;
    .menuIcon {
      display: none;
    }
  }

  .rightHeader {
    width: 19.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .headerOption {
      cursor: pointer;
      margin-left: 2rem;
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    padding: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .leftHeader {
      font-size: 1.8rem;
      span {
        display: none;
      }
      .menuIcon {
        display: block;
        color: #fff; /* Added color for the icon */
        font-size: 2rem; /* Adjust the size as desired */
        background: #000;
      }
      .sidebar {
        background: #000;
        height: 100vh; /* Updated to 100vh */
        width: 50%;
        border: none; /* Removed the border */
        display: flex;
        justify-content: flex-start;
        align-items: center; /* Added to center the content vertically */
        flex-direction: column;
        padding: 2rem;
        position: fixed; /* Changed to fixed for better positioning */
        top: 0;
        left: 0;
        z-index: 999; /* Added to ensure the sidebar appears above other elements */
        .optionsContainer {
          background: transparent;
          margin-right: 20vw;
          margin-top: 5rem;
          span {
            display: block;
            background: transparent;
            margin-bottom: 2rem;
          }
        }
      }
    }

    .rightHeader {
      width: auto;
      font-size: 1.4rem;
      .headerOption {
        display: none;
      }
    }
  }
}
