@import "@sweetalert2/theme-dark/dark.scss";

.swal2-popup,
.swal2-modal,
.swal2-icon-success,
.swal2-show {
  background: #161616;
  .swal2-title {
    background: transparent;
  }

  .swal2-html-container {
    background: transparent;
  }

  .swal2-icon,
  .swal2-success,
  .swal2-icon-show {
    .swal2-success-ring {
      background: transparent;
    }
    overflow: hidden;
  }

  .swal2-confirm,
  .swal2-styled {
    background-color: #ff8c00;
  }
}

.elevatorPitch {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: 35rem;
  margin-bottom: 9rem;
  .title {
    font-weight: 900;
    font-size: 5rem;
  }
  .description {
    font-size: 1.3rem;
    color: #555555;
  }
}

.bicontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20rem;
  .bicontainerTitle {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 4rem;
  }
  .containerBicontainer {
    display: flex;
    width: 80rem;
    align-items: center;
    justify-content: space-between;
    .indcontainer {
      border-radius: 25px;
      width: 35rem;
      display: flex;
      height: 35rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.65);
      box-shadow: 0 8px 32px 0 rgba(178, 119, 41, 0.37);
      backdrop-filter: blur(4.5px);
      -webkit-backdrop-filter: blur(4.5px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      .platform {
        font-weight: 600;
        font-size: 1.6rem;
        margin-bottom: 1rem;
        background: transparent;
        display: flex;
        text-align: center;
        flex-direction: column;
        .disclaimer {
          font-size: 0.8rem;
          background: transparent;
          color: #555555;
          text-align: center;
          margin-top: 0.919rem;
        }
      }
      .platformDescription {
        font-size: 1rem;
        background: transparent;
        color: #555555;
        ul,
        li {
          background: transparent;
        }
        li {
          margin-bottom: 1.837rem;
        }
      }
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ff8c00;
        border-radius: 10px;
        margin-top: 2.756rem;
        cursor: pointer;
        width: 10rem;
        height: 3rem;
        .buttonText {
          color: #ffffff;
          font-size: 1.1rem;
          background: transparent;
          text-align: center;
        }
      }
      .button--disabled {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-top: 2.756rem;
        cursor: default;
        width: 10rem;
        height: 3rem;
        background-color: #555555;
        .buttonText {
          color: #ffffff;
          font-size: 1.1rem;
          background: transparent;
          text-align: center;
        }
      }
    }
  }
}

.animateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30rem;
  margin-bottom: 5rem;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  .bigText {
    font-weight: 900;
    font-size: 3.6rem;
  }
  .smallText {
    font-size: 1.3rem;
    text-align: center;

    color: #555555;
  }
}

.faqContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70rem;
  .faqTitle {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 2rem;
    overflow: hidden;
  }
}

.interestContainer {
  background: rgba(48, 47, 47, 0.6);
  box-shadow: 0 8px 32px 0 rgba(178, 119, 41, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 2rem;
  flex-direction: column;
  padding-left: 2.4rem;
  width: 45rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-right: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8rem;
  .bigInterestText {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 2rem;
    background: transparent;
  }
  .smallInterestText {
    font-size: 1.3rem;
    background: transparent;
    color: #555555;
  }
  .joinNowButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff8c00;
    border-radius: 10px;
    margin-top: 2.756rem;
    cursor: pointer;
    width: 10rem;
    height: 3rem;
    .joinNowButtonText {
      color: #ffffff;
      font-size: 1rem;
      background: transparent;
      text-align: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .elevatorPitch {
    padding-top: 20vh;
    padding-bottom: 15vh;
    height: 20rem;

    .title {
      font-size: 4rem;
      overflow: hidden;
    }

    .description {
      font-size: 1.2rem;
      overflow: hidden;
      margin-bottom: 2rem;
    }
    .joinWaitlistContainer {
      width: 15rem;
      input {
        width: 9rem;
      }
      div {
        width: 4rem;
      }
    }
  }
  .bicontainer {
    .containerBicontainer {
      flex-direction: column;
      .indcontainer {
        width: 15rem;
        margin-bottom: 5rem;
        .platform {
          overflow: hidden;
        }
        .button {
          width: 10rem;
          height: 3.7rem;
          .buttonText {
            font-size: 1rem;
          }
        }
        .button--disabled {
          width: 10rem;
          height: 3.7rem;
          .buttonText {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .animateContainer {
    .bigText {
      font-size: 2.6rem;
      text-align: center;
    }
    .smallText {
      font-size: 1rem;
    }
  }
  .interestContainer {
    width: 15rem;
    margin-top: 8rem;
    .bigInterestText {
      font-size: 2rem;
    }
    .smallInterestText {
      font-size: 1.1rem;
    }
  }
}
